import { Controller } from '@hotwired/stimulus';
import AutocompleteSource from '../models/autocomplete_source';

export default class AutocompleteController extends Controller {
  static targets = ['input', 'searchInput', 'suggestionList', 'suggestionTemplate', 'emptyResult'];
  static values = {
    nextField: String,
    selectedId: String,
    selectedText: String
  };

  connect() {
    this.clearSuggestions();
    this.autocompleteSource = new AutocompleteSource('/stations/autocomplete', (data) => {
      this.showSuggestions(data);
    });
  }

  showSuggestions(suggestions) {
    this.clearSuggestions();

    this.suggestions = suggestions;

    if (this.suggestions.length > 0) {
      this.suggestions.forEach((suggestion) => {
        this.suggestionListTarget.append(
          this.createSuggestionElement(suggestion)
        );
      });

      this.highlightIndex(0);
    } else {
      this.suggestionListTarget.append(this.emptyResultTarget.cloneNode(true));
    }

    this.suggestionListTarget.classList.remove('hidden');
    this.suggestionListTarget.scrollTo({ top: 0, behavior: 'instant' });
    this.suggestionListTarget.scrollIntoView({ block: 'nearest' });
  }

  clearSuggestions() {
    this.suggestionListTarget.classList.add('hidden');

    this.suggestionListTarget.replaceChildren();
    this.currentHighlightIndex = null;
  }

  // Actions

  search() {
    this.autocompleteSource.query = this.searchInputTarget.value;
  }

  next() {
    if (this.currentHighlightIndex === this.suggestionElements.length - 1) {
      return;
    }

    this.highlightIndex(this.currentHighlightIndex + 1);
  }

  previous() {
    if (this.currentHighlightIndex === 0) {
      return;
    }

    this.highlightIndex(this.currentHighlightIndex - 1);
  }

  activate() {
    if (this.selectedIdValue.length > 0) {
      this.showSuggestions([{ id: this.selectedIdValue, text: this.selectedTextValue }]);
    }
  }

  focus() {
    this.searchInputTarget.select();
  }

  highlight(event) {
    this.highlightIndex(this.indexOfSuggestionElement(event.target));
  }

  select(event) {
    const targetIndex = this.indexOfSuggestionElement(event.target);
    if (targetIndex !== -1) { this.highlightIndex(targetIndex); }

    const selectedSuggestionElement = this.suggestionElements[this.currentHighlightIndex];
    if (selectedSuggestionElement === undefined) { return; }

    event.preventDefault();

    this.selectedIdValue = selectedSuggestionElement.dataset.id;
    this.selectedTextValue = selectedSuggestionElement.dataset.text;
    this.inputTarget.value = this.selectedIdValue;
    this.searchInputTarget.value = this.selectedTextValue;

    this.clearSuggestions();

    if (this.nextFieldValue != null) {
      document.querySelector(this.nextFieldValue).focus();
    }
  }

  cancel() {
    this.searchInputTarget.value = this.selectedTextValue;

    this.clearSuggestions();
  }

  // Internal

  get suggestionElements() {
    return this.suggestionListTarget.children;
  }

  indexOfSuggestionElement(element) {
    return [...this.suggestionElements].indexOf(element);
  }

  highlightIndex(index) {
    if (this.currentHighlightIndex === index) {
      return;
    }

    if (this.currentHighlightIndex !== null) {
      const suggestion = this.suggestionElements[this.currentHighlightIndex];
      suggestion.className = suggestion.dataset.autocompleteDefaultClass;
    }

    this.currentHighlightIndex = index;

    const suggestion = this.suggestionListTarget.children[this.currentHighlightIndex];
    suggestion.className = suggestion.dataset.autocompleteHighlightClass;

    suggestion.scrollIntoView({ block: 'nearest' });
  }

  createSuggestionElement(suggestion) {
    const suggestionElement = this.suggestionTemplateTarget.cloneNode(false);
    suggestionElement.innerText = suggestion.text;
    suggestionElement.dataset.id = suggestion.id;
    suggestionElement.dataset.text = suggestion.text;
    suggestionElement.dataset.autocompleteDefaultClass = suggestionElement.className;

    return suggestionElement;
  }
}
